.space-align-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.space-align-block {
    flex: none;
    margin: 8px 4px;
    padding: 4px;
    border: 1px solid #40a9ff;
}
.space-align-block .mock-block {
    display: inline-block;
    padding: 32px 8px 16px;
    background: rgba(150, 150, 150, 0.2);
}